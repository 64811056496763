import { Routes, Route } from "react-router-dom";

import './App.css';
import "./fontello/css/fontello.css";

// Componentes
import Home from './components/Home/Home.jsx';
import Servicios from './components/Servicios/Servicios.jsx';
import Nosotros from './components/Nosotros/Nosotros.jsx';
import Contacto from './components/Contacto/Contacto.jsx';
import Login from './components/Admin/Login.jsx';
import PanelAdmin from './components/Admin/PanelAdmin.jsx';
import NoFound from './components/noFound/NoFound.jsx';
import ProtectedRoute from "./ProtectedRoute.jsx";

const App = () => {
  
  return (
    <div className="App">

      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/nosotros" element={ <Nosotros /> } />
        <Route path="/servicios" element={ <Servicios /> } />
        <Route path="/contacto" element={ <Contacto /> } />
        <Route path="/admin/login" element={ <Login /> } />
        <Route path="/admin/panel" element={ <ProtectedRoute> <PanelAdmin /> </ProtectedRoute> }/>
        <Route path="*" element={<NoFound />} />
      </Routes>

    </div>
  );
}

export default App;
