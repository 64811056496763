// LogoutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡Serás desconectado de tu cuenta!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, cerrar sesión',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                // Eliminar el token de localStorage
                localStorage.removeItem('token');

                // Redirigir al usuario al login
                navigate('/admin/login');

                Swal.fire(
                    'Cerrado',
                    'Has cerrado sesión correctamente.',
                    'success'
                );
            }
        });
    };

    return (
        <button onClick={handleLogout}>
            Cerrar sesión
        </button>
    );
};

export default LogoutButton;
