import React, { useState, useRef, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { useForm, ValidationError } from '@formspree/react';
import Swal from 'sweetalert2';

import './contacto.css';

import Menu from "../Menu/Menu.jsx";
import Footer from '../Footer/Footer.jsx';

import contactoImg from '../../Assets/Images/contactoImg.jpg';

const Contacto = () => {

    const form = useRef();
    const [state, handleSubmit] = useForm("xanwvjrr");

    if (state.succeeded) {
        Swal.fire({
            position: "center",
            icon: "success",
            title: "Mensaje enviado con exito",
            showConfirmButton: false,
            timer: 2500
        });

        document.getElementById('form_container').reset();
    }

    const showInputs = (event) => {
        if (Event.value.lenght >= 1) {
            Event.parentElement.classList.add('hasInput');
        } else {
            Event.parentElement.classList.remove('hasInput');
        }
    }

    return (
        <div>

            <Menu />

            <section className='containerFormImage'>
                <img className='imagenForm' src={contactoImg} alt='imagen contacto proveedoramys'></img>

                <form id='form_container' className='form_container' ref={form} onSubmit={handleSubmit} action="https://formspree.io/f/xanwvjrr" method='POST'>
                    <h2>Formulario de contacto</h2>

                    <div className='input_container'>
                        <input className='inputContact' type='text' name='name_Client' oninput={() => showInputs()} autoComplete='off' spellCheck='false' required />
                        <label className='labelContact'>Nombre</label>
                    </div>

                    <div className='input_container'>
                        <input className='inputContact' type='text' name='email_Client' oninput={() => showInputs()} autoComplete='off' spellCheck='false' required />
                        <label className='labelContact'>Correo</label>
                    </div>

                    <div className='input_container'>
                        <input className='inputContact' type='text' name='tel_Client' oninput={() => showInputs()} autoComplete='off' spellCheck='false' required />
                        <label className='labelContact'>Numero Telefonico</label>
                    </div>

                    <div className='input_container'>
                        <input className='inputContact' type='text' name='message_Client' oninput={() => showInputs()} autoComplete='off' spellCheck='false' required />
                        <label className='labelContact'>Mensaje</label>
                    </div>

                    <div className='boxBtonSendMessage'>
                        <input className='btnSendMessage' type='submit' value='Enviar Mensaje' />
                    </div>
                </form>
            </section>

            <h3 className='titleInfoContact'>Informacion de contacto</h3>
            <hr className='hrContact' />

            <Fade>
                <section className='containerInfoContact'>
                    <article>
                        <h4><span className='icon-mail-alt'></span>correo</h4>
                        <p>ventas@sbsuministrosltda.com</p>
                    </article>

                    <article>
                        <h4><span className='icon-phone'></span>telefono</h4>
                        <p>+57 3237999282</p>
                        <p>2016175</p>
                    </article>

                    <article>
                        <h4><span className='icon-location'></span>Direccion</h4>
                        <p>Carrera 22 No 15-47 Bogotá</p>
                    </article>
                </section>
            </Fade>

            <p className='textContact'>¡No esperes más para tener las herramientas que necesitas para tu próximo proyecto! Contáctanos ahora y te ayudaremos a encontrar todo lo que necesitas en nuestra amplia selección de equipo de ferretería de alta calidad.</p>

            <Footer />

        </div>
    );
};

export default Contacto;