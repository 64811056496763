import React from 'react';

import './footer.css';

// Footer images
import FooterLogo from '../../Assets/Logos/logosuministrosltda1.png';
import instagram from '../../Assets/Images/instagram.png';
import facebook from '../../Assets/Images/facebook.png';
import whatsapp from '../../Assets/Images/whatsapp.png';

const Footer = () => {
    return (
        <div>
            
            <footer>
                <img className='footerLogo' src={FooterLogo} alt="Logo footer"></img>

                <article className='infoContact'>
                    <div>
                        <h3>Telefono</h3>
                        <p>+57 3237999282</p>
                        <p>2016175</p>
                    </div>

                    <div>
                        <h3>Email</h3>
                        <p>ventas@sbsuministrosltda.com</p>
                    </div>
                </article>

                <article className='containerSocialMedia'>
                    <h3>SIGUENOS EN NUESTRAS REDES SOCIALES</h3>
                    <div className='socialMedia'>
                        <a href="https://wa.me/573237999282?text=Hola,%20quiero%20conocer%20m%C3%A1s%20productos" target='_blank'><img src={whatsapp} alt="whatsapp"></img></a>
                        <a href="https://m.facebook.com/people/S-B-Suministros-Ltda/61557081192293/" target="_blank"><img src={facebook} alt="facebook"></img></a>
                        <a href="https://www.instagram.com/suministrosltda?igsh=MTgxbjlyNmp0OGZ3bw==" target="_blank"><img src={instagram} alt="instagram"></img></a>
                    </div>
                </article>
            </footer>
            
            <p className='copyrigth'>&copy; 2024 SBsuministros LTDA. All rights reserved.</p>
        
        </div>
    );
};

export default Footer;