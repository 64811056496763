// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem("token"); // Verifica si el token está en el localStorage

    if (!token) {
        return <Navigate to="/admin/login" replace />; // Redirige a la página de login si no está autenticado
    }

    return children; // Renderiza los hijos si está autenticado
};

export default ProtectedRoute;
