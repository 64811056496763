import React, { useState } from "react";
import Swal from 'sweetalert2';
import LogoutButton from './LogoutButton';

const PanelAdmin = () => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [photo, setPhoto] = useState(null);
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validar que los campos no estén vacíos
        if (!name || !description || !photo) {
            setError("Todos los campos son obligatorios.");
            return;
        }

        // Crear un objeto FormData para enviar archivos
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("photo", photo);

        try {
            const response = await fetch("https://api.sbsuministrosltda.com/nuevoproducto", {
                method: "POST",
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                setName("");
                setDescription("");
                setPhoto(null);

                Swal.fire({
                    title: '¡Bien hecho!',
                    text: 'Producto creado correctamente',
                    icon: 'success',
                    confirmButtonText: 'cool'
                })

            } else {
                setError(data.message || "Error al crear el producto.");
            }
        } catch (error) {
            setError("Error de conexión.");
        }
    };

    return (

        <div>

            <section className="headerAdmin">
                <h2>Panel Administrativo</h2>
                <LogoutButton />
            </section>

            <div className="product-form-container">
                <h2>Crear Nuevo Producto</h2>

                {error && <p style={{ color: "red" }}>{error}</p>}

                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div>
                        <label htmlFor="name">Nombre del producto:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="description">Descripción:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="photo">Foto del producto:</label>
                        <input
                            type="file"
                            id="photo"
                            accept="image/*"
                            onChange={(e) => setPhoto(e.target.files[0])}
                            required
                        />
                    </div>
                    <button type="submit">Crear Producto</button>
                </form>
            </div>
        </div>
    );
};

export default PanelAdmin;
