import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import logo1 from '../../Assets/Logos/logosuministrosltda7.png';
import logo2 from '../../Assets/Logos/logo suministrosltda5.png';

const Menu = () => {

  const [logoHeader, setLogoHeader] = useState('');
  const [classHeader, setClassHeader] = useState('');
  const [isResponsive , setIsResponsive] = useState(false);
  const location = useLocation();
  let topRef = useRef(null);

  const activeBtns = () => {
    topRef.current.scrollIntoView(true);
    setIsResponsive(false);
  }

  const changeClassHeader = () => {
    if (location.pathname !== '/') {
      setLogoHeader(logo2);
      setClassHeader('header2');
    } else {
      setLogoHeader(logo1);
      setClassHeader('header');
    }
  };

  useEffect(() => {
    changeClassHeader();
  }, [location]); // El efecto se ejecutará cada vez que cambie la ubicación


  return (
    <div>
      <header>
        <button className="icon-align-justify" onClick={() => setIsResponsive(!isResponsive)}></button>
        <nav className={isResponsive ? "menuResponsive" : ""}>
        <img src={logoHeader} alt="logo" title="Logo-SBsuministros-LTDA"></img>
          <ul>
            <Link to="/"><li className="itemMenu">inicio</li></Link>
            <Link to="/servicios"><li className="itemMenu">servicios</li></Link>
            <Link to="/nosotros"><li className="itemMenu">nosotros</li></Link>
            <Link to="/contacto"><li className="itemMenu">contacto</li></Link>
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Menu;