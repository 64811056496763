import React from 'react';
import './servicios.css';

const Modal = ({ show, onClose, name, description, image, urlWhatsapp }) => {

    if (!show) {
        return null;
    }

    return (
        <div>
            <div className="modal-overlay">
                <div className="modal-content">
                    <button className="modal-close" onClick={onClose}>X</button>
                    <img src={image} alt={name} className="modal-image" />
                    <h2 className="modal-title">{name}</h2>
                    <p className="modal-description">Descripcion: {description}</p>
                    <div className="modal-buttons">
                        <a href={urlWhatsapp} target='_blank'><button className="btnCardProduct">Preguntar <span className="icon-whatsapp"></span></button></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;