
import React from 'react';
import { Bounce } from "react-awesome-reveal";
import { useRef, useState, useEffect } from "react";

import './servicios.css';

import Menu from "../Menu/Menu.jsx";
import Footer from '../Footer/Footer.jsx';
import ProductCard from './Productos.jsx';

import women from '../../Assets/Images/fondoHome2.jpg';
import pdfCatalogo from '../../Assets/CATALOGOSBSUMINISTROSLTDA.pdf';

const Servicios = () => {

    const [data, getData] = useState();
    let topRef = useRef(null);

    // search the products
    const searchProduct = async () => {
        const response = await fetch(`https://api.sbsuministrosltda.com/productos`)
        const json = await response.json();
        getData(json);
    }

    useEffect(() => {
        searchProduct();
    }, [])

    return (
        <div>

            <Menu />

            <section className='containerServices'>
                <div className='infoServices'>
                    <p>Herramientas y materiales de construcción a tu alcance
                        Recibe asesoría experta y servicios premium para llevar tus proyectos al siguiente nivel. No te pierdas ninguna actualización, ¡únete ahora!</p>

                    <a href='https://wa.me/573237999282?text=Hola,%20quiero%20conocer%20m%C3%A1s%20productos' target='_blank'>contactanos <span className="icon-whatsapp"></span></a>
                </div>

                <Bounce>
                    <img className='imageWomen' src={women} alt='mujer señalando'></img>
                </Bounce>
            </section>

            <p className="textProdSellMore">Descubre nuestros productos</p>

            <article className="product-list">
                {(data !== undefined) ?
                    data.map((product) => {

                        let urlWhatsapp = 'https://wa.me/573237999282?text=' + product.NOMBREPROD;

                        return <ProductCard
                            key={product.ID}
                            image={product.URLIMG1}
                            name={product.NOMBREPROD}
                            description={product.DESCRIPCION}
                            urlWhatsapp={urlWhatsapp}
                        />

                    })
                    :
                    <p>Buscando productos</p>
                }
            </article>

            <section className='containerMoreInfo'>
                <article className='articleMoreInfo'>
                    <span className='icon-credit-card'></span>
                    <div>
                        <h4>Metodos de Pago</h4>
                        <p>Recibimos todas las tarjetas,pagos en efectivo y transferencias</p>
                    </div>
                </article>

                <hr />

                <article className='articleMoreInfo'>
                    <span className='icon-users'></span>
                    <div>
                        <h4>Equipo Especializado</h4>
                        <p>Nos enfocamos en atenderte de la mejor manera</p>
                    </div>
                </article>

                <hr />

                <article className='articleMoreInfo'>
                    <span className='icon-stopwatch'></span>
                    <div>
                        <h4>Tiempo de Respuesta</h4>
                        <p>Nuestra atención siempre puesta a los clientes</p>
                    </div>
                </article>
            </section>

            <section className="sectionImageInfo">
                <article className="backgroundImage">
                    <p>Para SBsuministros LTDA, es de gran importancia contar
                        con clientes como ustedes y tener el placer de atender sus requerimientos diarios, es
                        por esto que brindamos un amplio portafolio de productos, relacionado a
                        continuación. </p>
                    <a href={pdfCatalogo} target="_blank">
                        <button className="btnLinkProductsInfo"><span className="icon-right-hand"></span>Ver Catalogo</button>
                    </a>
                </article>
            </section>

            <Footer />

        </div>
    );
};

export default Servicios;