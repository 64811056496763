import React from 'react';
import './noFound.css'; // Importa el archivo de estilos

const NotFound = () => {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1 className="not-found-title">404</h1>
                <h2 className="not-found-subtitle">Oops! Página no encontrada</h2>
                <p className="not-found-text">
                    Lo sentimos, la página que estás buscando no existe. 
                    Puede que hayas escrito mal la dirección o que la página haya sido movida.
                </p>
                <a href="/" className="not-found-button">Volver al inicio <span className="icon-paper-plane"></span></a>
            </div>
        </div>
    );
};

export default NotFound;