import React from 'react';
import { useState } from "react";

import './servicios.css';
import Modal from './Modal.jsx';

const ProductCard = ({ image, name, urlWhatsapp, description }) => {

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (<div>

        <Modal
            show={showModal}
            onClose={handleCloseModal}
            urlWhatsapp={urlWhatsapp}
            name={name}
            description={description}
            image={image}
        />

        <article className="product-card">
            <img src={image} alt={name} className="product-image" />
            <h2 className="product-name">{name}</h2>
            <div>
                <a href={urlWhatsapp} target='_blank'><button className="btnCardProduct">Preguntar <span className="icon-whatsapp"></span></button></a>
                <button className="btnCardProduct" onClick={() => handleOpenModal()}>Ver Mas</button>
            </div>
        </article>
    </div>

    );
};

export default ProductCard;
