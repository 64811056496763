import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import "./admin.css";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Todos los campos son obligatorios.");
            return;
        }

        setError(""); // Resetea el error

        fetch('https://api.sbsuministrosltda.com/autenticarUsuario', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                correo: email,
                contraseña: password,
            })
        })
            .then(response => response.json().then(data => ({ status: response.status, body: data.message })))
            .then(({ status, body }) => {

                if (status === 200 && body) {
                    // Almacena el token en el localStorage
                    localStorage.setItem("token", body);

                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Bienvenido Administrador",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {
                        navigate('/admin/panel');
                    }, 2000);
                } else {
                    Swal.fire({
                        title: '¡Oops! tuvimos un problema',
                        text: 'Ha ocurrido un error al intentar iniciar sesión',
                        icon: 'error',
                        confirmButtonText: 'Intentar nuevamente'
                    });

                    setPassword("");
                }
            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'Error de red',
                    text: 'No pudimos conectar con el servidor. Por favor, intenta de nuevo más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar'
                });
            });
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Iniciar Sesión</h2>
                {error && <p className="error-message">{error}</p>}
                <input
                    type="email"
                    placeholder="Correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Ingresar</button>
            </form>
        </div>
    );
};

export default Login;
