import { useState, useEffect, useRef } from 'react';

const useCountAnimation = (endValue) => {
    const [count, setCount] = useState(0);
    const elementRef = useRef();

    useEffect(() => {
        let observer;
        let animationFrameId;

        const startCounting = () => {
            const duration = 2000; // Duración de la animación en ms
            const startTime = performance.now();

            const updateCount = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1);
                const currentCount = Math.floor(progress * endValue);
                setCount(currentCount);

                if (progress < 1) {
                    animationFrameId = requestAnimationFrame(updateCount);
                }
            };

            animationFrameId = requestAnimationFrame(updateCount);
        };

        if (elementRef.current) {
            observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    startCounting();
                    observer.disconnect(); // Detiene la observación después de que comience la animación
                }
            });

            observer.observe(elementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
            if (animationFrameId) cancelAnimationFrame(animationFrameId);
        };
    }, [endValue]);

    return [count, elementRef];
};

export default useCountAnimation;
